var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "扫描二维码",
        "modal-append-to-body": false,
        "close-on-click-modal": false,
        visible: _vm.showDialog,
        width: "40%",
        "custom-class": "qrcodeDialog",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
        close: _vm.closeDialog,
      },
    },
    [
      _c("div", { ref: "qrcodeBox", staticClass: "qrcodeBox" }, [
        _c("img", {
          staticStyle: { width: "200px", height: "200px" },
          attrs: { src: _vm.qrcodeSrc, alt: "" },
        }),
        _c("div", { staticClass: "qrcodeBox_text" }, [
          _vm._v(_vm._s(_vm.qrcodeText)),
        ]),
        _c("div", { staticClass: "qrcodeBox_text" }, [
          _vm._v(_vm._s(_vm.qrcodeCode)),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }